import { useState } from 'react';

export default function DeliverDate({ deliverDateSelected }) {
  const [deliverDate] = useState(
    new Date(deliverDateSelected).toLocaleDateString('fr-FR'),
  );
  let span;

  if (deliverDateSelected && deliverDate && deliverDateSelected !== '1970-01-01 00:00:00') {
    span = <span className="fw-600 fsz-14">{deliverDate}</span>;
  } else {
    span = <span className="fw-600 dangerous fsz-14">Non renseignée</span>;
  }

  return (
    <div className="info-item-container d-flex flex-column">
      <span className="my-4">Date de livraison souhaitée</span>
      {span}
    </div>
  );
}
