import styled from '@emotion/styled';
import style from 'contanst/style';
import MultipleSelect from 'components/ui/multiple-select';
import BATItem from 'project/bat/BATItem';
import { useContext } from 'react';
import { ProjectContext } from 'project/index';

const Wrapper = styled(MultipleSelect)`
  .checkbox-label {
    margin-right: 10px;
  }
  .bat-header {
    padding-left: 21px;
  }
  .bat-content {
    padding: 35px 25px;
    .bat-content_name {
      justify-content: space-between;
      align-items: center;
      .bat-content_name_title {
        .bat-content_name_label {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 160%;
          color: #000000;
          margin-left: 53px;
        }
      }
      .bat-content_name_actions {
        .icon-button {
          & + .icon-button {
            margin-left: 20px;
          }
        }
        label {
          width: 50%;
        }
      }
    }
    .bat-content_count {
      margin: 10px 0 20px;
      .bat-content_count_label {
        padding-left: 53px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 130.5%;
        color: #000000;
      }
      .bat-content_name_actions {
      }
    }
    .bat-content_main {
      flex-wrap: wrap;
      padding: 0 53px;
      .bat-content_main_left {
        width: 60%;
        padding-right: 50px;
        img {
          width: 100%;
        }
      }
      .bat-content_main_right {
        border-radius: 5px;
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .bat-comment {
          background: #f9f9f9;
          border-radius: 5px;
          padding: 20px 15px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .bat-content_main_history_item {
          p {
            margin-top: 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130.5%;
            color: #000000;
          }
          div {
            margin-top: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130.5%;
            color: #909090;
          }
        }
        .bat-content_main_send_box {
          align-items: center;
          margin-top: 20px;
          .bat-content_main_send_box_content {
            height: 90px;
            flex: 1;
            textarea {
              height: 90px;
              border: solid 1px #909090;
              border-radius: 5px;
              resize: none;
            }
          }
          .bat-content_main_send_box_button {
            width: 35px;
            height: 35px;
            background: ${style.primaryColor};
            border-radius: 50%;
            margin: 0 5px 0 20px;
            svg {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
    }
  }
  .no-data_bat {
    min-height: 115px;
    background: #fafafa;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    @media only screen and (max-width: 440px) {
      font-size: 12px;
      margin-bottom: -20px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .bat-content {
      .bat-content_main {
        padding: 0;
      }
    }
  }
  @media only screen and (max-width: 940px) {
    .bat-content {
      .bat-content_name {
        .bat-content_name_title {
          .bat-content_name_label {
            margin-left: 0;
          }
        }
      }
      .bat-content_count {
        .bat-content_count_label {
          padding-left: 0;
        }
      }
      .taille-note-accept {
        margin-left: 0;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .bat-content {
      .bat-content_count {
        margin-bottom: 40px;
      }
      .bat-content_main {
        .bat-content_main_left {
          width: 100%;
          padding: 0;
        }

        .bat-content_main_right {
          margin: 30px -25px -35px;
          width: calc(100% + 50px);
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .bat-content {
      padding: 15px 15px;
    }
    .bat-content {
      .bat-content_name {
        .bat-content_name_title {
          .bat-content_name_label {
            margin-left: 0;
          }
        }
      }
      .bat-content_count {
        .bat-content_count_label {
          padding-left: 0;
        }
      }
      .bat-content_main {
        .bat-content_main_right {
          margin: 20px -15px -15px;
          width: calc(100% + 30px);
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .bat-content {
      .bat-content_name {
        .bat-content_name_title {
          flex-direction: column-reverse;
          align-items: self-start !important;
          .bat-content_name_label {
            font-size: 14px;
            margin-left: 0;
          }
        }
      }
      .bat-content_count {
        margin-top: 0;
        margin-bottom: 25px;
        .bat-content_count_label {
          font-size: 14px;
          padding-left: 0;
        }
      }
      .bat-content_main {
        .bat-content_main_right {
          .bat-content_main_history_item {
            font-size: 12px;
            p,
            div {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .bat-content {
      .bat-content_name {
        .bat-content_name_title {
          .bat-content_name_label {
            font-size: 12px;
          }
        }
      }
      .bat-content_count {
        .bat-content_count_label {
          font-size: 12px;
        }
      }
      .bat-content_main {
        .bat-content_main_right {
          .bat-content_main_history_item {
            font-size: 10px;
            p,
            div {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
`;

export default function Bat() {
  const { data }: any = useContext(ProjectContext);
  const products = data.products || [];

  return (
    <Wrapper onClickAction={items => console.log(items)}>
      {products.length ? (
        products.map(item => {
          const { id } = item;

          return item.designed_id >= 0 ? (
            <BATItem value={id} key={id} {...item} />
          ) : (
            ''
          );
        })
      ) : (
        <div className="no-data_bat d-flex align-item-center justify-content-center">
          Le BAT est en cours de réalisation
        </div>
      )}
    </Wrapper>
  );
}
