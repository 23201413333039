import styled from '@emotion/styled';
import { forwardRef, InputHTMLAttributes } from 'react';
import style from 'contanst/style';

const Wrapper = styled('textarea')`
  background: #ffffff;
  border: solid 1px ${style.primaryColor};
  border-radius: 5px;
  padding: 8px;
  width: 100%;
`;

type TextAreaProps = {
  rows?: number;
  onClick?: (e) => void;
} & Omit<InputHTMLAttributes<any>, 'rows' | 'type' | 'onClick'>;

function TextArea(props: TextAreaProps, ref) {
  return <Wrapper {...props} ref={ref} />;
}

export default forwardRef(TextArea);
