import styled from '@emotion/styled';
import Checkbox from 'components/ui/checkbox';
import { ReactNode, useContext } from 'react';
import { MultipleSelectContext } from 'components/ui/multiple-select/index';
import clsx from 'clsx';

const Wrapper = styled.div``;

type SelectItemProps = {
  value: string | number | object;
  className?: string;
  children?: ((checkbox) => ReactNode) | ReactNode;
};

export default function SelectItem({
  value,
  children,
  className,
}: SelectItemProps) {
  const { selectedItems, setSelectedItems }: any = useContext(
    MultipleSelectContext,
  );
  const isSelected = selectedItems.includes(value);

  const handleSelect = selected => {
    if (!selected) {
      return setSelectedItems(prev => prev.filter(item => value !== item));
    }

    return setSelectedItems(prev => [...prev, value]);
  };

  const checkbox = <Checkbox onSelect={handleSelect} selected={isSelected} />;

  return (
    <Wrapper className={clsx(className, 'select-item')}>
      {typeof children === 'function' ? (
        children(checkbox)
      ) : (
        <>
          <div className="checkbox-item">{checkbox}</div>
          {children}
        </>
      )}
    </Wrapper>
  );
}
