export default function isEmpty(object) {
  if (Array.isArray(object)) {
    return !object.length;
  }

  return (
    !Object.keys(object || {}).length ||
    !Object.keys(object).filter(key => object[key]).length
  );
}
