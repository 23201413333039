import styled from '@emotion/styled';
import { InputHTMLAttributes, ReactNode, useCallback } from 'react';
import style from 'contanst/style';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
const Wrapper = styled.label<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  cursor: ${props => (!props.disabled ? 'pointer' : 'not-allowed')};
  .checkbox-label {
    color: ${props =>
      !props.disabled ? style.primaryColor : style.disabledColor};
    margin-left: 8px;
    font-weight: 600;
  }
  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 0px;
    height: 0px;
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
    color: ${props =>
      !props.disabled ? style.primaryColor : style.disabledColor};
    &:active {
      opacity: 1;
      transform: scale(0);
      transition: transform 0s, opacity 0s;
    }
    & + span {
      display: inline-flex;
      .checked {
        display: none;
      }
      svg {
        color: ${props =>
          !props.disabled ? style.primaryColor : style.disabledColor};
        width: 22px;
        height: 22px;
      }
    }
    &:checked {
      & + span {
        .blank {
          display: none;
        }
        .checked {
          display: inline-flex;
        }
      }
    }
  }
`;

type CheckboxProps = {
  children?: ReactNode;
  onSelect?: (checked) => void;
  selected?: boolean;
  indeterminate?: boolean;
  label?: string;
  disabled?: boolean;
} & Omit<InputHTMLAttributes<any>, 'onSelect'>;

export default function Checkbox({
  onSelect,
  selected,
  label,
  children = label,
  indeterminate,
  disabled,
  ...extraProps
}: CheckboxProps) {
  const handleSelect = useCallback(e => {
    onSelect && onSelect(e.target.checked);
  }, []);

  return (
    <Wrapper disabled={disabled}>
      <input
        type="checkbox"
        onChange={handleSelect}
        checked={selected}
        disabled={disabled}
        {...extraProps}
      />
      <span>
        {!indeterminate && (
          <>
            <CheckBoxOutlineBlankIcon className="blank" />
            <CheckBoxIcon className="checked" />
          </>
        )}

        {indeterminate && <IndeterminateCheckBoxIcon />}
      </span>
      {children && <span className="checkbox-label">{children}</span>}
    </Wrapper>
  );
}
