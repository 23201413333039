import styled from '@emotion/styled';
import { InputHTMLAttributes } from 'react';
import style from 'contanst/style';

const Wrapper = styled('input')`
  background: #ffffff;
  border: solid 1px ${style.primaryColor};
  height: 35px;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
`;

type InputProps = {} & Omit<InputHTMLAttributes<any>, 'type' | 'onClick'>;

export default function Input(props: InputProps) {
  return <Wrapper {...props} />;
}
