import styled from '@emotion/styled';
import SelectItem from 'components/ui/multiple-select/SelectItem';
import IconButton from 'components/ui/button/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PdfViewer from 'components/ui/pdf-viewer';
import PdfViewerFirstPage from 'components/ui/pdf-viewer/PdfViewerFirstPage';
import downloadFile from 'utils/downloadFile';
import { addLog } from 'utils/api';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { CommentDispatchContext, ProjectContext } from 'project';
import DropdownMenu from 'components/ui/dropdow-menu';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import { TARGET_DOCUMENT_COMMENT } from 'project/comments';

const Wrapper = styled('div')`
  padding: 25px;
  width: 25%;
  .select-item {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 10px 15px;
  }
  .left {
    flex: 1;
    .document-name {
      word-break: break-word;
      font-weight: 700;
      font-size: 12px;
      margin-left: 10px;
    }
  }
  .right {
    .icon-button {
      & + .icon-button {
        margin-left: 20px;
      }
    }
  }
  .document-image {
    padding: 15px 32px;
    img {
      width: 100%;
    }
  }
  .action-document {
    .icon-button {
      height: 22px;
      & + .icon-button {
        margin-left: 20px;
      }
    }
  }
  @media only screen and (max-width: 1700px) {
    width: 25%;
  }
  @media only screen and (max-width: 1500px) {
    .right {
      .icon-button {
        & + .icon-button {
          margin-left: 10px !important;
        }
      }
    }
    width: 33.33%;
  }
  @media only screen and (max-width: 1100px) {
    width: 50%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 15px;
    .document-image {
      padding: 15px;
    }
  }
`;

type DocumentItemProps = {
  version_id: number;
  value: string | number | object;
  documentName: string;
  fileUrl: string;
  dlFileUrl: string;
  hasComment: boolean;
  projectFileId: string;
};

export default function DocumentItem({
  version_id,
  value,
  fileUrl,
  projectFileId,
  documentName,
  hasComment,
  dlFileUrl,
}: DocumentItemProps) {
  const { data }: any = useContext(ProjectContext);
  const dispatch = useContext(CommentDispatchContext);

  const uniqLink = useParams().id;

  const dlDoc = function () {
    downloadFile(dlFileUrl, documentName);
    let linkedTo = {
      target: 'CustomerProject',
      target_id: data.id,
      log_type: 19,
    };
    addLog(
      uniqLink,
      'FileVersion',
      version_id,
      3,
      JSON.stringify({ linked_to: linkedTo }),
    );
  };

  const seeDoc = function () {
    let linkedTo = {
      target: 'CustomerProject',
      target_id: data.id,
      log_type: 21,
    };
    addLog(
      uniqLink,
      'FileVersion',
      version_id,
      4,
      JSON.stringify({ linked_to: linkedTo }),
    );
  };

  const handleOpenComment = () => {
    dispatch({
      type: TARGET_DOCUMENT_COMMENT,
      open: true,
      targetId: projectFileId,
      isValidate: false,
    });
  };

  return (
    <Wrapper>
      <SelectItem value={value}>
        {() => (
          <>
            <div className="d-flex align-item-center justify-content-between">
              <div className="left d-flex align-item-center ">
                {/* {checkbox} */}
                <span className="document-name">{documentName}</span>
              </div>
              <div className="right d-flex align-item-center action-document">
                <PdfViewer
                  file={fileUrl}
                  filename={documentName}
                  onDownload={() => dlDoc()}
                  trigger={
                    <IconButton
                      onClick={() => seeDoc()}
                      icon={<VisibilityOutlinedIcon />}
                      tooltip="Visualiser"
                    />
                  }
                />
                <DropdownMenu
                  className="icon-button"
                  menuItems={[
                    {
                      icon: <FileDownloadOutlinedIcon />,
                      label: 'Télécharger',
                      onClick: dlDoc,
                    },
                    {
                      icon: hasComment ? (
                        <MarkChatUnreadOutlinedIcon />
                      ) : (
                        <AddCommentOutlinedIcon />
                      ),
                      label: 'Commenter',
                      onClick: handleOpenComment,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="document-image">
              <PdfViewerFirstPage file={fileUrl} />
            </div>
          </>
        )}
      </SelectItem>
    </Wrapper>
  );
}
