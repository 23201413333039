import styled from '@emotion/styled';
import Address from 'project/informations/Address';
import Contact from 'project/informations/Contact';
import { useContext, useState } from 'react';
import { CommentDispatchContext, ProjectContext } from 'project/index';
import isEmpty from 'utils/isEmpty';
import useFetchData from 'utils/useFetchData';
import { getAllCustomerContact, getCheckWays } from 'utils/api';
import { useParams } from 'react-router-dom';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import IconButton from 'components/ui/button/IconButton';
import { TARGET_INFORMATION_COMMENT } from 'project/comments';
import CheckWays from './CheckWays';
import DeliverDate from './DeliverDate';
import PaymentTo from './PaymentTo';
const Wrapper = styled.div`
  //padding: 0 30px;
  margin: -15px 0;
  flex-wrap: wrap;
  position: relative;
  .comment-icon {
    position: absolute;
    right: 15px;
    top: -18px;
    @media only screen and (max-width: 768px) {
      top: -10px;
    }
    @media only screen and (max-width: 600px) {
      top: 0px;
    }
  }

  .info-item-container {
    width: 25%;
    padding: 15px;
    .my-4 {
      font-weight: 500;
    }
    .info-item {
      color: #000000;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      max-width: 300px;
      .title-information {
        font-size: 12px;
      }
      .box-info {
        border: 1px solid #e9e7ff;
        border-radius: 5px;
        padding: 8px;
      }
      .address_content {
        font-size: 14px;
        font-style: normal;
        line-height: 170.5%;
        font-weight: 500;
        word-break: break-word;

        .address_name {
            font-weight: 600;
        }
        .zip {
          color: #003C9D
        }
      }
      .bold {
        font-weight: 600;
      }
      .title-information {
        // min-height: 60px;
        margin-bottom: 12px;
        margin-left: 2px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        .same-billing-address {
          margin-top: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    .info-item-container {
      width: 33.33%;
    }
  }
  @media only screen and (max-width: 1200px) {
    padding: 0;
  }
  @media only screen and (max-width: 960px) {

    .info-item-container {
      width: 50%;
    }
  }
  @media only screen and (max-width: 680px) {
    .info-row {
      flex-wrap: wrap;
    }
    .info-item-container {
      width: 100%;
      .info-item {
        max-width: 100%;
        .title-information {
          min-height: 30px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    margin: -15px;
  }
`;

export default function Informations() {
  const { data }: any = useContext(ProjectContext);
  const [checkWays, setCheckWays] = useState<any[]>([]);

  if (!checkWays.length) {
    getCheckWays().then(resp => {
      setCheckWays(resp as any[]);
    });
  }

  const dispatch = useContext(CommentDispatchContext);
  const isValidate = data.is_validate || false;
  const billingAddress = data.billing_address || {};
  const shippingAddress = data.shipping_address || {};
  const customers = data.customers || [];
  const hasComment = data.has_comment_informations;
  const cwSelected = data.custom_values.customer_proj_payment_ways;
  const ptSelected = data.custom_values.customer_proj_payment_to;
  const ddSelected = data.custom_values.customer_proj_delivery_date;
  const { id } = useParams();
  const { data: customer } = useFetchData(getAllCustomerContact, { id });
  const { addresses = [] }: any = customer || {};
  const contact = customers?.find(item => +item.is_signatory === 1);

  const handleOpenComment = () => {
    dispatch({
      type: TARGET_INFORMATION_COMMENT,
      open: true,
      isValidate: false,
    });
  };

  return (
    <Wrapper className="d-flex flex-column">
      <IconButton
        icon={
          hasComment ? (
            <MarkChatUnreadOutlinedIcon />
          ) : (
            <AddCommentOutlinedIcon />
          )
        }
        tooltip="Commenter"
        className="comment-icon"
        onClick={handleOpenComment}
      />

      <div className="d-flex justify-content-around info-row">
        <DeliverDate deliverDateSelected={ddSelected} />
        <CheckWays checkWaysArray={checkWays} checkWaySelected={cwSelected} />
        <PaymentTo paymentToSelected={ptSelected} />
      </div>
      <div className="d-flex justify-content-around info-row">
        {!isEmpty(shippingAddress) && (
          <Address
            isValidate={isValidate}
            title="Adresse de livraison"
            addresses={addresses}
            type="shipping"
            address={{
              rowid: shippingAddress.rowid,
              firstname: shippingAddress.firstname,
              lastname: shippingAddress.lastname,
              note_public: shippingAddress.note_public,
              nom: shippingAddress.nom,
              statut: shippingAddress.statut,
              address: shippingAddress.address || '',
              zip: shippingAddress.zip || '',
              town: shippingAddress.town || '',
              fk_user_creat: shippingAddress.fk_user_creat || '0',
            }}
          />
        )}
        {!isEmpty(billingAddress) && (
          <Address
            isValidate={isValidate}
            title="Adresse de facturation"
            hasCkbSameAddress
            addresses={addresses}
            type="billing"
            address={{
              rowid: billingAddress.rowid,
              firstname: billingAddress.firstname,
              lastname: billingAddress.lastname,
              note_public: billingAddress.note_public,
              statut: billingAddress.statut,
              nom: billingAddress.nom,
              address: billingAddress.address || '',
              zip: billingAddress.zip || '',
              town: billingAddress.town || '',
              fk_user_creat: billingAddress.fk_user_creat || '0',
            }}
          />
        )}
        <Contact
          isValidate={isValidate}
          contact_empty={isEmpty(contact)}
          contact={
            isEmpty(contact)
              ? {
                  fullName: '',
                  email: '',
                  phone: '',
                  poste: '',
                  error: 'Aucun contact assigné',
                }
              : contact
          }
        />
      </div>
    </Wrapper>
  );
}
