import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #2f3640;
  color: #718093;
  h1 {
    font-size: 100px;
    margin-bottom: 0px;
    color: #f5f6fa;
  }
  p {
    font-size: 50px;
    margin-top: 0px;
  }
`;

type ErrorProps = {
  msg: string;
};

export default function Error({ msg }: ErrorProps) {
  return (
    <Wrapper>
      <h1>Erreur !</h1>
      <p>{msg}</p>
    </Wrapper>
  );
}
