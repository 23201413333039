import styled from '@emotion/styled';
import Header from 'project/Header';
import { createContext, useEffect, useReducer, useRef, useState } from 'react';

import HorizontalMenu from '../components/ui/horizontalMenu';
import { useParams } from 'react-router-dom';
import { getAllInfos, addLog } from 'utils/api';
import Tabs from 'project/Tabs';
import BannerProjectValidated from 'components/ui/banner_project_validated';
import axios from 'axios';
import Bat from './bat';
import Tailles from './tailles';
import Document from './document';
import Informations from './informations';
import loadable from 'utils/loadable';
import Comments from 'project/comments';
import commentsReducer from 'project/comments/commentsReduer';
const SignatureForm = loadable(() => import('project/signature'));

const Wrapper = styled.div`
  margin: 67px 72px 0;

  &.header-sticky {
    margin-top: 250px;
    .nav-tab {
      margin-top: 0;
    }
  }
  .select-all {
    margin-left: 25px;
    margin-bottom: 35px;
  }
  .btn-generate-documents {
    padding: 60px 0 120px;
    button {
      width: 290px;
    }
  }
  .header-project {
    top: 0;
    position: fixed;
    margin: 0 72px;
    left: 0;
    right: 0;
    background: #faf9fe;
    z-index: 1;
  }
  .container-tabs {
    margin-top: 228px;
  }
  &.has_banner_validated {
    .container-tabs {
      margin-top: 280px;
    }
  }

  @media only screen and (max-width: 768px) {
    /* For desktop: */
    margin: 25px 25px 0;
    .header-project {
      margin: 0 25px;
    }
    .select-all {
      margin-left: 16px;
    }
    .rc-collapse-content-box {
      margin-top: 0 !important;
    }
  }
  @media only screen and (max-width: 700px) {
    &.header-sticky {
      // margin-top: 277px;
    }
    .client-info_right_content {
      display: flex;
      align-items: center;
      .client-info_left_content_usermail {
        margin-top: 0 !important;
        margin-left: 15px;
      }
    }
    &.has_banner_validated {
      .container-tabs {
        margin-top: 305px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    overflow-x: hidden;
    margin-top: 0;
    &.header-sticky {
      margin-top: 0;
      .nav-tab {
        margin-top: 10px;
      }
    }
    .container-tabs {
      margin-top: 175px;
      #tab_bat {
        .collapse {
          margin-top: 0;
        }
      }
    }
    &.has_banner_validated {
      .container-tabs {
        margin-top: 240px;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    &.header-sticky {
      margin-top: 0;
    }
    .container-tabs {
      margin-top: 140px;
    }
    &.has_banner_validated {
      .container-tabs {
        margin-top: 180px;
      }
    }
  }
`;

interface Table {
  name: string;
  valid: number;
  quantity: number;
  total: number;
}

export const CommentContext = createContext(null);
export const CommentDispatchContext = createContext<any>(null);
export const ProjectDispatchContext = createContext<any>(null);
export const ProjectContext = createContext<{
  data;
}>({
  data: undefined,
});

function projectReducer(data, action) {
  return { ...(data || {}), ...action };
}

export default function Project() {
  const [allValidTotalTable, setAllValidTotalTable] = useState({});
  const [comments, dispatch] = useReducer(commentsReducer, undefined);
  const [data, dispatchProject] = useReducer(projectReducer, undefined);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const refHeader = useRef<any>();

  useEffect(() => {
    setLoading(true);
    getAllInfos({ id })
      .then(dispatchProject)
      .finally(() => setLoading(false));
  }, []);

  function handleQuantitiesChange(value) {
    const newTables = Object.entries<Table>(value).reduce((acc, [, val]) => {
      if (val.valid !== 0) {
        const table = {
          valid: val.valid,
          name: val.name,
          quantity: val.quantity,
          total: val.total,
        };

        return [...acc, table];
      }

      return acc;
    }, []);
    setAllValidTotalTable(newTables);
  }

  const tabs = [
    {
      title: 'BAT',
      id: 'bat',
      component: <Bat />,
    },
    {
      title: 'Tailles',
      id: 'tailles',
      component: (
        <Tailles onQuantitiesChangeCallback={handleQuantitiesChange} />
      ),
    },
    {
      title: 'Documents',
      id: 'document',
      component: <Document />,
    },
    {
      title: 'Informations',
      id: 'informations',
      component: <Informations />,
    },
  ];

  const logVisited = () => {
    axios
      .get(`${process.env.REACT_APP_APICTOR_URL}/server/infos`)
      .then(function (response) {
        let ip = response.data.ip;
        let userAgent = response.data.user_agent;
        if (userAgent.includes('GoogleBot')) return;
        if (process.env.REACT_APP_ADDR_SP!.includes(ip)) return;
        addLog(id, '', 0, 7);
      });
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    const sections = document.querySelectorAll('.tab-content-item');

    function navHighlighter() {
      const scrollY = window.pageYOffset;
      sections.forEach(current => {
        // @ts-ignore
        const sectionHeight = current.offsetHeight;
        const sectionTop =
          current.getBoundingClientRect().top + window.pageYOffset - 180;
        const sectionId = current.getAttribute('id');

        if (
          scrollY + 30 > sectionTop &&
          scrollY + 30 <= sectionTop + sectionHeight
        ) {
          document
            .querySelector(`[data-id=${sectionId}]`)
            ?.classList?.add('selected');
        } else {
          document
            .querySelector(`[data-id=${sectionId}]`)
            ?.classList?.remove('selected');
        }
      });
    }

    window.addEventListener('scroll', navHighlighter);

    return () => window.removeEventListener('scroll', navHighlighter);
  }, [loading]);

  useEffect(() => {
    logVisited();
    let wrapper: any;
    let header: any;

    function detectScroll() {
      header = header ? header : document.querySelector('.header-project');
      wrapper = wrapper
        ? wrapper
        : document.querySelector('.container-project');

      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        header?.classList.add('sticky');
        wrapper?.classList.add('header-sticky');
      } else {
        header?.classList.remove('sticky');
        wrapper?.classList.remove('header-sticky');
      }
    }

    window.addEventListener('scroll', detectScroll);

    return () => window.removeEventListener('scroll', detectScroll);
  }, []);

  if (loading || !data) {
    return (
      <div
        className="loading-data-tab d-flex align-item-center justify-content-center"
        style={{ height: '100vh' }}
      >
        <img src="/imgs/sploader.gif" alt="" />
      </div>
    );
  }

  return (
    <ProjectContext.Provider value={{ data }}>
      <ProjectDispatchContext.Provider value={dispatchProject}>
        <CommentContext.Provider value={comments}>
          <CommentDispatchContext.Provider value={dispatch}>
            <Wrapper className="container-project">
              <div className="header-project" ref={refHeader}>
                <Header />
                <HorizontalMenu items={tabs} className="nav-tab" />
                <BannerProjectValidated />
              </div>
              <Tabs tabs={tabs} />
              <div className="btn-generate-documents d-flex align-item-center justify-content-end">
                <SignatureForm allTableValid={allValidTotalTable} />
              </div>
            </Wrapper>
            <Comments />
          </CommentDispatchContext.Provider>
        </CommentContext.Provider>
      </ProjectDispatchContext.Provider>
    </ProjectContext.Provider>
  );
}
