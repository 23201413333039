import styled from '@emotion/styled';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DropdownMenu from 'components/ui/dropdow-menu';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import clsx from 'clsx';
import Checkbox from 'components/ui/checkbox';
import Modal from 'components/ui/modal';
import { ProjectContext } from 'project/index';
import { MenuItem } from 'rc-menu';
import Form from 'components/ui/form';
import Field from 'components/ui/form/Field';
import { ReactElement, useContext, useMemo, useState } from 'react';
import Select from 'components/ui/select';
import AddIcon from '@mui/icons-material/Add';
import { changeAddress, createAddress, updateAddress } from 'utils/api';
import { useParams } from 'react-router-dom';
const Wrapper = styled.div`
  position: relative;
  height: 100%;
  .icon-button {
    position: absolute;
    right: 10px;
  }

  .info-item {
    height: 100%;
  }
  .box-info {
    padding-right: 25px;
  }

  #create-address {
    display: none;
  }
`;

type AddressType = {
  rowid: string;
  firstname: string;
  lastname: string;
  note_public: string;
  address: string;
  town: string;
  zip: string;
  nom?: string;
  statut: string;
  fk_user_creat: string;
};

type AddressProps = {
  title: string;
  className?: string;
  address: AddressType;
  hasCkbSameAddress?: boolean;
  type: 'shipping' | 'billing';
  addresses?: any[];
  isValidate;
};

export default function Address({
  title,
  type,
  hasCkbSameAddress,
  addresses,
  className = '',
  isValidate,
  address,
}: AddressProps) {
  const result = useContext(ProjectContext);
  const customerIdentifier = result.data.customer_identifier;
  const [addressState, setAddress] = useState(address);
  const {
    note_public: notePublic,
    firstname = '',
    lastname = '',
    nom,
    address: addressString,
    town,
    zip,
  } = addressState;
  const [selectAddress, setSelectAddress] = useState(false);

  const optionsAddress: any = useMemo(() => {
    const data: any = addresses?.map(item => ({
      label: item.address,
      value: item.identifier,
    }));

    return [
      {
        value: 0,
        label: 'Choisir une adresse',
      },
      ...(data || []),
      {
        value: 'add_new',
        label: (
          <>
            {' '}
            <AddIcon style={{ marginRight: '6px' }} /> Créer une adresse
          </>
        ),
      },
    ];
  }, [addresses]);

  const { id } = useParams();

  const handleChangeAddress = () => {
    setSelectAddress(true);
  };

  const handleSelectAddress = addressId => {
    if ([0, 'add_new'].includes(addressId)) {
      if (addressId === 'add_new') {
        let element: HTMLElement = document.querySelector(
          `#create-address.${type}`,
        ) as HTMLElement;
        element.click();
      }

      return;
    }

    setSelectAddress(false);
    setAddress(addresses?.find(item => item.identifier === addressId));
    changeAddress(id, { type, identifier: addressId });
  };

  const WrapperFormEditAddress = styled(Modal)``;

  type FormAddressProps = {
    initialValues?: any;
    isEdit?: boolean;
    triggerModal?: ReactElement;
  };

  const FormEditAddress = ({
    initialValues = {},
    isEdit = true,
    triggerModal,
  }: FormAddressProps) => {
    const menu =
      initialValues.fk_user_creat === '-1' ? (
        <MenuItem className="menu-item d-flex align-item-center pointer">
          <span className="icon">
            <EditOutlinedIcon />
          </span>
          <span className="label">Editer l’adresse</span>
        </MenuItem>
      ) : (
        <></>
      );

    const handleSubmit = (values, onCancel) => {
      values.statut = values.statut ? 1 : 0;
      values.note_private = 'address_client';

      if (!isEdit)
        return createAddress(id, values).then(addressId => {
          setSelectAddress(false);
          setAddress({
            rowid: addressId,
            firstname: values.firstname,
            lastname: values.lastname,
            note_public: values.note_public,
            statut: values.statut,
            address: values.address,
            zip: values.zip,
            town: values.town,
            fk_user_creat: '-1',
          });
          changeAddress(id, {
            type,
            identifier: `${customerIdentifier}::socpeople::${addressId}`,
          }).then(() => {
            window.location.reload();
            onCancel();
          });
        });
      else
        return updateAddress(id, values).then(addressId => {
          setAddress({
            rowid: addressId,
            firstname: values.firstname,
            lastname: values.lastname,
            note_public: values.note_public,
            statut: values.statut,
            address: values.address,
            zip: values.zip,
            town: values.town,
            fk_user_creat: '-1',
          });
          onCancel();
        });
    };

    return (
      <WrapperFormEditAddress
        triggerModal={triggerModal || menu}
        title={isEdit ? 'Editer l’adresse' : 'Créer une adresse'}
      >
        {onClose => (
          <Form
            onSubmit={handleSubmit}
            onCancel={onClose}
            initialValues={{
              ...initialValues,
              statut: initialValues?.statut === '1',
            }}
          >
            <div className="row">
              <div className="col-6">
                <Field name="note_public" label="Libellé" required />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Field name="firstname" label="Prénom" />
              </div>
              <div className="col-6">
                <Field name="lastname" label="Nom" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field name="address" label="Adresse" required />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Field name="zip" label="Code postal" required />
              </div>
              <div className="col-6">
                <Field name="town" label="Ville" required />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  name="statut"
                  noneLabel
                  component={Checkbox}
                  label="Définir comme adresse par défaut"
                />
              </div>
            </div>
          </Form>
        )}
      </WrapperFormEditAddress>
    );
  };

  return (
    <div className="info-item-container">
      <Wrapper className={clsx(className, 'info-item')}>
        <div className="title-information">
          {title}
          {hasCkbSameAddress && (
            <div></div>
            // <div className="same-billing-address">
            //   <Checkbox disabled={isValidate}>Identique à l'adresse de facturation</Checkbox>
            // </div>
          )}
        </div>
        <FormEditAddress
          isEdit={false}
          triggerModal={<span id="create-address" className={type}></span>}
        />
        {selectAddress && (
          <Select
            options={optionsAddress}
            defaultValue={0}
            onSelect={handleSelectAddress}
          />
        )}
        {!selectAddress && (
          <div className="box-info address_content">
            <div className="town bold d-flex align-item-center justify-content-between">
              {notePublic || addressString ? notePublic : 'Sans adresse'}
              {!isValidate && (
                <DropdownMenu
                  menuItems={[
                    {
                      icon: <PinDropOutlinedIcon />,
                      label: 'Changer d’adresse',
                      onClick: handleChangeAddress,
                    },
                    {
                      renderMenu: (
                        <FormEditAddress
                          key={addressState.rowid}
                          initialValues={addressState}
                        />
                      ),
                    },
                  ]}
                />
              )}
            </div>
            {(firstname || lastname) && (
              <div className="firstname address_name">{`${firstname} ${lastname}`}</div>
            )}
            {nom && <div className="firstname">{nom}</div>}
            {addressString && <div className="address">{addressString}</div>}
            {(zip || town) && (
              <div className="zip">
                {zip} {town}
              </div>
            )}
          </div>
        )}
      </Wrapper>
    </div>
  );
}
