import React from 'react';
import styled from '@emotion/styled';
import Collapse from 'components/ui/collapse';
import CommentItem from './CommentItem';
const Wrapper = styled(Collapse)``;
type Props = {
  open: boolean;
  documentComments: any[];
};

const CommentDocuments = ({ open, documentComments }: Props) => {
  return (
    <Wrapper
      header="Documents"
      enableCollapse
      className="comment-section"
      defaultOpen={open}
    >
      {!documentComments?.length ? (
        <div className="txt-empty-comment">
          Aucun commentaire pour la section "Documents".
        </div>
      ) : (
        documentComments.map(({ id, comment, title, created_on: date }) => (
          <CommentItem key={id} comment={comment} title={title} date={date} />
        ))
      )}
    </Wrapper>
  );
};

export default CommentDocuments;
