import { useEffect, useState } from 'react';

export default function useFetchData(
  fetchData: (params) => Promise<any>,
  params: object = {},
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    setLoading(true);
    fetchData(params)
      .then(setData)
      .finally(() => setLoading(false));
  }, []);

  return { loading, data };
}
