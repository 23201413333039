import styled from '@emotion/styled';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
const Wrapper = styled('div')`
  margin: 1.5rem 0;
  flex-wrap: wrap;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130.5%;
  }
  //span.warning:before {
  //  content: url('/icons/warning.svg');
  //}
  //span.dangerous:before {
  //  content: url('/icons/dangerous.svg');
  //}
  //span.success:before {
  //  content: url('/icons/success.svg');
  //}
  //span:before {
  //  margin-right: 5px;
  //}
  .message {
    margin-left: 6pt;
  }
`;

type MessageProps = {
  type: string;
  message: string;
};

export default function Message({ type, message }: MessageProps) {
  let classType = '';
  let icon;

  switch (type) {
    case 'warning':
      classType = 'warning';
      icon = <WarningAmberOutlinedIcon />;
      break;
    case 'dangerous':
      classType = 'dangerous';
      icon = <CancelOutlinedIcon />;
      break;
    case 'success':
      classType = 'success';
      icon = <CheckCircleOutlineOutlinedIcon />;
      break;
    default:
      classType = '';
      break;
  }

  return (
    <Wrapper>
      {classType !== '' ? (
        <span className={`w-100 ${classType} d-flex align-items-center`}>
          {icon}
          <span className={'message '}>{message}</span>
        </span>
      ) : (
        ''
      )}
    </Wrapper>
  );
}
