import styled from '@emotion/styled';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ReactElement, useEffect, useRef, useState } from 'react';
import throttle from 'utils/throttle';
import Modal from 'components/ui/modal';
import IconButton from 'components/ui/button/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Scrollbar from 'components/ui/scrollbar';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
const Wrapper = styled(Modal)`
  min-height: 100vh;
  margin: auto !important;
  canvas {
    width: auto;
    max-width: initial;
  }
  @media only screen and (max-width: 900px) {
    margin: auto !important;
    width: 100vw !important;
  }
  .rc-dialog-content {
    max-height: 100vh;
    width: 100%;
    background: #00000080;
    border-radius: 0;
    overflow-x: hidden;
    min-width: 100vw;
    .rc-dialog-close {
      display: none;
    }
    .rc-dialog-body {
      padding: 0;
      max-height: 100vh;
      overflow: hidden;
      position: relative;
      .header-viewer {
        background: #000000cc;
        padding: 25px;
        .filename {
          margin-left: 25px;
          font-size: 14px;
          font-weight: 600;
        }
        * {
          color: #ffffff;
        }
      }
      .content-viewer {
        width: 297mm;
        max-width: calc(100% - 150px);
        max-height: calc(100vh - 95px);
        min-height: calc(100vh - 95px);
        @media only screen and (max-width: 768px) {
          width: 100vw;
        }
        margin: 20px auto 0;
        overflow-y: auto;
        overflow-x: hidden;
        // max-width: 297mm;
        position: relative;
        .react-pdf__message {
          color: #ffffff;
          font-size: 2em;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
        }
        .page-viewer {
          & + .page-viewer {
            margin-top: 25px;
          }
        }
        canvas {
          margin: auto;
          //max-width: 297mm;
        }
      }
      .footer-viewer {
        position: fixed;
        bottom: 25px;
        left: 0;
        right: 0;
        .footer-container {
          background: rgba(0, 0, 0, 0.8);
          border-radius: 5px;
          width: 300px;
          margin: auto;
          //padding: 12px 12px;
          * {
            color: #ffffff;
            font-weight: 500;
            font-size: 12px;
          }
          .page-num,
          .page-zoom {
            padding: 12px;
            flex: 1;
            > * {
              padding: 0 10px;
            }
          }
          .page-zoom {
            border-left: 1px solid rgba(255, 255, 255, 0.17);
          }
        }
      }
    }
  }
`;

type PdfViewerProps = {
  trigger: ReactElement;
  file: string;
  filename: string;
  onDownload?: () => void;
};

export default function PdfViewer({
  trigger,
  file,
  filename,
  onDownload,
}: PdfViewerProps) {
  const [numPages, setNumPages] = useState(0);
  const [scale, setScale] = useState(1);
  const [initialWidth, setInitialWidth] = useState(0);
  const pdfWrapper = useRef(null);
  const viewerContainer = useRef<HTMLDivElement>(null);

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      // @ts-ignore
      setInitialWidth(pdfWrapper.current?.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    if (viewerContainer && viewerContainer.current) {
      const viewerContainerEl = viewerContainer.current;
      const canvas = viewerContainerEl.querySelector('canvas');
      const contentViewer = viewerContainerEl.querySelector(
        '.content-viewer',
      ) as HTMLDivElement;

      if (contentViewer) {
        contentViewer.style.width = `${canvas?.clientWidth}px`;
      }
    }
  }, [scale]);

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 3000));
    setPdfSize();

    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 3000));
    };
  }, []);

  useEffect(() => {
    function handleClick(e) {
      const viewer = document.querySelector('.pdf-viewer');
      const dialogContent = viewer?.querySelector('.rc-dialog-content');
      const element = e?.target as HTMLDivElement;
      const close = dialogContent?.querySelector(
        '.close-viewer',
      ) as HTMLDivElement;

      if (
        element?.classList?.contains('scroll-container') ||
        element?.classList?.contains('container-content')
      ) {
        close?.click();
      }
    }

    window?.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const handleLoadSuccess = pdf => {
    setPdfSize();
    setNumPages(pdf.numPages);
  };

  const handleZoomIn = () => {
    setScale(prev => prev + 0.2);
  };

  const handleZoomOut = () => {
    if (scale === 0.2) {
      return;
    }

    setScale(prev => prev - 0.2);
  };

  const handleScroll = e => {
    const container = e.target;
    const pages = container.querySelectorAll('.page-viewer');
    pages.forEach(page => {
      const { height, top } = page.getBoundingClientRect();
      const elCurrentPage = container.querySelector('.current-page');
      const heightWindow = window.innerHeight;

      if (top < heightWindow && height + top > 200) {
        const pageNum = page.getAttribute('data-page-number');
        elCurrentPage.innerText = pageNum;
      }
    });
  };

  return (
    <Wrapper
      triggerModal={trigger}
      width="100%"
      height="100%"
      wrapClassName="pdf-viewer"
    >
      {onClose => (
        <div className="container-content">
          <div className="header-viewer d-flex justify-content-between align-item-center">
            <div className="left d-flex align-item-center">
              <IconButton
                className="close-viewer"
                icon={<ArrowBackIcon />}
                onClick={() => {
                  onClose();
                  setScale(1);
                }}
              />
              <span className="filename">{filename}</span>
            </div>
            <div className="right">
              <IconButton
                icon={<FileDownloadOutlinedIcon />}
                onClick={onDownload}
              />
            </div>
          </div>
          <div ref={viewerContainer} className="container-viewer">
            <Scrollbar className="content-viewer" onScroll={handleScroll}>
              <div ref={pdfWrapper}>
                <Document
                  file={file}
                  onLoadSuccess={handleLoadSuccess}
                  loading={<img src={'/imgs/sploader.gif'} alt="" />}
                >
                  <>
                    {!!numPages &&
                      [...Array(numPages).keys()].map(page => (
                        <Page
                          pageNumber={page + 1}
                          className="page-viewer"
                          renderTextLayer={false}
                          width={initialWidth}
                          key={page}
                          scale={scale}
                        />
                      ))}
                    <div className="footer-viewer">
                      <div className="footer-container  d-flex align-item-center justify-content-between align-item-center">
                        <div className="page-num d-flex justify-content-between align-item-center">
                          <span className="page-label">Page</span>
                          <span className="current-page">1</span>
                          <span className="spread-page">/</span>
                          <span className="total-pages">{numPages}</span>
                        </div>
                        <div className="page-zoom d-flex justify-content-between align-item-center">
                          <span className="zoom-btn">
                            <IconButton
                              icon={<RemoveOutlinedIcon />}
                              onClick={handleZoomOut}
                            />
                          </span>
                          <span className="zoom-icon">{`${Math.round(
                            scale * 100,
                          )}%`}</span>

                          <span className="zoom-btn">
                            <IconButton
                              icon={<AddOutlinedIcon />}
                              onClick={handleZoomIn}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                </Document>
              </div>
            </Scrollbar>
          </div>
        </div>
      )}
    </Wrapper>
  );
}
