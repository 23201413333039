import { ReactNode, useMemo, memo, forwardRef } from 'react';
import {
  RegisterOptions,
  useFormContext,
  useController,
} from 'react-hook-form';
import * as React from 'react';
import styled from '@emotion/styled';
import Input from 'components/ui/input';
import get from 'lodash/get';
import style from 'contanst/style';
const Wrapper = styled.div<{ isError?: boolean; hasError?: boolean }>`
  width: 100%;
  .field-label {
    display: block;
    color: ${style.primaryColor};
    line-height: 20px;
    font-weight: 500;
    .hot {
      color: #c71f2c;
    }
  }
  .field-container {
    margin-bottom: 20px;
  }
  input {
    ${props =>
      (props.isError || props.hasError) &&
      'border: solid 1px #C71F2C !important;'}
  }
  .error-holder {
    color: #c71f2c;
    font-size: 0.8em;
    margin-top: 5px;
    font-weight: 500;
  }
`;

export type FieldProps = {
  component?: any;
  required?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  name: string;
  label?: string | any | ReactNode;
  initialValue?: string | any;
  id?: string;
  className?: string;
  msgRequired?: string;
  componentProps?: object;
  autoFocus?: boolean;
  noneLabel?: boolean;
  isFieldSignature?: boolean;
  rules?: RegisterOptions;
  hasError?: boolean;
};

const Field = forwardRef(
  (
    {
      component = Input,
      name = '',
      label = '',
      className = '',
      msgRequired = '*Veuillez remplir le champ obligatoire',
      componentProps = {},
      initialValue: defaultValue,
      id = name,
      rules = {},
      required,
      isFieldSignature,
      noneLabel,
      hasError,
      ...extraComponentProps
    }: FieldProps,
    ref,
  ) => {
    const Component = component;
    const context = useFormContext();
    // @ts-ignore
    const { control } = context;
    const mergeRulesRequired = useMemo(() => {
      const requiredRule = required && msgRequired;

      return {
        ...rules,
        required: requiredRule,
      };
    }, [rules, required, label]);

    const {
      field: { ...inputProps },
      //  fieldState: { invalid, isTouched, isDirty },
      formState: { errors },
    } = useController({
      name,
      control,
      rules: mergeRulesRequired,
      defaultValue,
      shouldUnregister: true,
    });
    const isError = !!get(errors, `${name}.message`);

    const handleChange = e => {
      inputProps.onChange(e);
    };

    return (
      <Wrapper isError={isError} hasError={hasError} className={className}>
        <div className="field-container {isError}">
          <div className="error-holder">
            {isError && get(errors, `${name}.message`)}
          </div>
          <div className="field-control">
            {!noneLabel && label && (
              <label className="field-label" htmlFor={id}>
                {isFieldSignature ? (
                  <>
                    {label}
                    {required && <span className="required">*</span>}
                  </>
                ) : (
                  <>
                    {label}:{' '}
                    {required && (
                      <>
                        (<span className="hot">*</span>)
                      </>
                    )}
                  </>
                )}
              </label>
            )}
            <Component
              {...inputProps}
              {...componentProps}
              {...extraComponentProps}
              onChange={handleChange}
              error={isError}
              ref={ref}
              label={label}
              id={id}
            />
          </div>
        </div>
      </Wrapper>
    );
  },
);

export default memo(Field);
