import styled from '@emotion/styled';
import { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import style from 'contanst/style';
import Trigger from 'rc-trigger';
import 'rc-trigger/assets/index.css';
import Loading from 'components/ui/loading';
const Wrapper = styled.span<{ disable?: boolean }>`
  display: inline-flex;
  cursor: ${props => (!props.disable ? 'pointer' : 'not-allowed')};

  svg {
    width: 22px;
    height: 22px;
    color: ${props =>
      !props.disable ? style.primaryColor : style.disabledColor};
  }
  &.disable,
  &.loading {
    color: #a39a9acc;
    pointer-events: none;
    cursor: not-allowed;
    svg {
      color: #a39a9acc;
    }
  }
`;
const WrapperTooltip = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(75, 63, 180, 0.2);
  border-radius: 10px;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 10px;
  color: ${style.primaryColor};
`;

type IconButtonProps = {
  icon: ReactNode;
  disable?: boolean;
  loading?: boolean;
  tooltip?: string;
  onClick?: (e?) => void;
  className?: string;
};

function IconButton(
  {
    icon,
    disable,
    onClick,
    loading,
    className = '',
    tooltip,
    ...extraProps
  }: IconButtonProps,
  ref,
) {
  const iconButton = (
    <Wrapper
      disable={disable}
      onClick={disable ? undefined : onClick}
      ref={ref}
      className={clsx(className, 'icon-button', { disable, loading })}
      {...extraProps}
    >
      {loading ? <Loading /> : icon}
    </Wrapper>
  );

  if (!tooltip) {
    return iconButton;
  }

  return (
    <Trigger
      action={['hover']}
      popup={<WrapperTooltip>{tooltip}</WrapperTooltip>}
      popupAlign={{
        points: ['cc', 'bc'],
        offset: [0, 10],
      }}
    >
      {iconButton}
    </Trigger>
  );
}

export default forwardRef(IconButton);
