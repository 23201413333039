import styled from '@emotion/styled';
import { useContext } from 'react';
import { ProjectContext } from 'project/index';

const Wrapper = styled.div`
  div.client-info {
    display: flex;
    justify-content: space-between;
    div.client-info_left {
      display: flex;
      div.client-info_left_image {
        background: #fff;
        border-radius: 50px;
        width: 100px;
        height: 100px;
        padding: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
        }
      }
      div.client-info_left_content {
        margin-left: 20px;
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        div.client-info_left_content_name {
          font-weight: 600;
          font-size: 20px;
          line-height: 160%;
          margin-bottom: 10px;
          color: #003c9d;
        }
        div.client-info_left_content_code {
          font-weight: 500;
          font-size: 18px;
          line-height: 130.5%;
          color: #000000;
        }
      }
    }
    div.client-info_right {
      display: flex;
      div.client-info_right_content {
        padding: 16px 0px;
        .divider {
          margin: 0 5px;
        }
        div.client-info_left_content_username {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 160%;
          align-items: center;
          text-align: right;
          color: #000000;
        }
        div.client-info_left_content_usermail {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 130.5%;
          /* or 23px */
          display: flex;
          align-items: center;
          text-align: right;
          color: #000000;
          margin-top: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    /* For desktop: */
    div.client-info {
      display: flex;
      div.client-info_left {
        display: flex;
        div.client-info_left_image {
          img {
          }
        }
        div.client-info_left_content {
          margin-left: 20px;
          div.client-info_left_content_name {
            font-weight: 600;
            font-size: 20px;
            line-height: 160%;
            color: #003c9d;
          }
          div.client-info_left_content_code {
            font-weight: 600;
            font-size: 18px;
            line-height: 130.5%;
            color: #000000;
          }
        }
      }
      div.client-info_right {
        display: flex;
        div.client-info_right_content {
          padding: 16px 0px;
          div.client-info_left_content_username {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 160%;
            align-items: center;
            text-align: right;
            color: #000000;
          }
          div.client-info_left_content_usermail {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 130.5%;
            /* or 23px */
            display: flex;
            align-items: center;
            text-align: right;
            color: #000000;
            margin-top: 10px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    /* For tablets: */
    div.client-info {
      display: flex;
      flex-wrap: wrap;
      div.client-info_left {
        display: flex;
        width: 100%;
      }
      div.client-info_right {
        display: flex;
        justify-content: start !important;
        width: 100%;
        .client-info_left_content_username {
          text-align: left !important;
        }
        .client-info_left_content_usermail {
          flex-direction: row-reverse;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    div.client-info {
      div.client-info_right {
        div.client-info_right_content {
          padding: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    /* For tablets: */
    div.client-info {
      .client-info_left_content_name {
        font-size: 16px !important;
        margin-bottom: 7px !important;
      }
      .client-info_left_content_code {
        font-size: 12px !important;
      }
      .client-info_left_content_username {
        font-size: 14px !important;
      }
      .client-info_left_content_usermail {
        font-size: 12px !important;
      }
      div.client-info_left {
        display: flex;

        div.client-info_left_image {
          width: 60px;
          height: 60px;
          padding: 12px;
        }
      }
    }
  }
`;

export default function Header() {
  const { data }: any = useContext(ProjectContext);
  const {
    user_logo: userLogo,
    custom_values: customValues,
    reference,
    manager,
  } = data;
  const phone = manager.phone_1 || manager.phone_2;

  const getProjectLogo = () => {
    if (Object.keys(customValues).length > 0)
      if (typeof customValues.customer_proj_picture_info !== 'undefined')
        return `/helpers/getfile.php?file=${customValues.customer_proj_picture_info}`;

    return `/helpers/getfile.php?file=${userLogo}`;
  };

  const getProjectName = () => {
    if (typeof customValues.customer_proj_name === 'undefined')
      return 'Lien Projet';

    return customValues.customer_proj_name;
  };

  return (
    <Wrapper>
      <head>
        <title>{`${getProjectName()}`}</title>
        <link
          rel="icon"
          href={`${getProjectLogo()}`}
          type="image/icon type"
        ></link>
      </head>
      <div className={'client-info'}>
        <div className={'client-info_left'}>
          <div className={'client-info_left_image'}>
            <img src={`${getProjectLogo()}`} />
          </div>
          <div className={'client-info_left_content flex-row'}>
            <div className={'client-info_left_content_name'}>
              {customValues?.customer_proj_name}
            </div>
            <div className={'client-info_left_content_code'}>{reference}</div>
          </div>
        </div>
        <div className={'client-info_right justify-content-end'}>
          <div className={'client-info_right_content'}>
            <div className={'client-info_left_content_username'}>
              {`${manager.first_name} ${manager.last_name}`}
            </div>
            <div className={'client-info_left_content_usermail'}>
              {phone && (
                <>
                  <span className="phone">{phone}</span>
                  <span className="divider">|</span>
                </>
              )}
              <span className="email">{manager.email}</span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
