import styled from '@emotion/styled';
import style from 'contanst/style';
import { useRef } from 'react';

const Wrapper = styled.div`
  margin-top: 50px;
  //position: sticky;
  //top: 0;
  background: #faf9fe;
  z-index: 2;
  ul {
    border-bottom: 1px solid #d8d8d8;
    li {
      list-style: none;
      width: 25%;
      text-align: center;
      align-items: center;
      color: #000000;
      font-weight: 600;
      font-size: 20px;
      line-height: 160%;
      a {
        color: inherit;
        font-size: inherit;
        color: inherit;
        font-style: inherit;
        text-decoration: inherit;
        display: block;
        width: 100%;
      }
      &.selected {
        color: #003c9d;
        border-bottom: 5px solid #003c9d;
      }
      &:hover {
        border-bottom: 5px solid #003c9d;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    /* For desktop: */
    margin-top: 50px;
    ul {
      li {
        list-style: none;
        width: 25%;
        text-align: center;
        align-items: center;
        color: #000000;
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        &.selected {
          color: #003c9d;
          border-bottom: 5px solid #003c9d;
        }
        &:hover {
          border-bottom: 5px solid #003c9d;
          cursor: pointer;
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    /* For desktop: */
    margin-top: 10px;
  }
  @media only screen and (max-width: 600px) {
    /* For tablets: */
    margin-top: 20px;
    ul {
      justify-content: space-around;
      border-bottom: 1px solid #d8d8d8;
      width: 100%;
      margin: 0 0;
      padding: 0;
      li {
        list-style: none;
        width: auto;
        text-align: center;
        align-items: center;
        color: #000000;
        font-weight: 600;
        font-size: 15px;
        line-height: 160%;
        position: relative;
        &.selected {
          color: #003c9d;
          border-bottom: 1px solid #003c9d;
          &:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2.5px;
            content: '';
            height: 5px;
            display: block;
            border-radius: 3px;
            background: ${style.primaryColor};
          }
        }
        &:hover {
          border: 0;
          //border-bottom: 5px solid #003c9d;
          cursor: pointer;
          &:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2.5px;
            border-radius: 3px;
            content: '';
            height: 5px;
            display: block;
            background: ${style.primaryColor};
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    margin-top: 10px;
    ul {
      justify-content: space-between;
      li {
        &.selected {
          &:after {
            height: 5px !important;
          }
        }
        &:hover {
          &:after {
            height: 0;
          }
        }
      }
    }
  }
`;

const HeaderItem = ({ className = '', item }) => {
  const handleClickTab = e => {
    const tabElement = document.getElementById(`tab_${item.id}`);
    const currentTabHeader = e.target;
    const allTabHeader = document.querySelectorAll(
      '.header-project .tab-header-item',
    );
    allTabHeader.forEach(tabHeader => {
      tabHeader.classList.remove('selected');
    });

    currentTabHeader.classList.add('selected');
    window.scrollTo({
      top:
        (tabElement?.offsetTop || 0) -
        (document.querySelector('.header-project')?.clientHeight || 0),
      behavior: 'smooth',
    });
    setTimeout(function () {
      window.scrollTo({
        top:
          (tabElement?.offsetTop || 0) -
          (document.querySelector('.header-project')?.clientHeight || 0),
        behavior: 'smooth',
      });
      currentTabHeader.classList.add('selected');
    }, 500);
    setTimeout(function () {
      allTabHeader.forEach(tabHeader => {
        tabHeader.classList.remove('selected');
      });
      currentTabHeader.classList.add('selected');
    }, 1000);
  };

  return (
    <li
      key={item.id}
      data-id={`tab_${item.id}`}
      className={`${className} tab-header-item`}
      onClick={handleClickTab}
    >
      {item.title}
    </li>
  );
};

type MenuProps = {
  items: any;
  className?: string;
};

export default function HorizontalMenu({ items, className = '' }: MenuProps) {
  const activeDefaultTab = useRef('bat');

  return (
    <Wrapper className={className}>
      <ul className={'d-flex'}>
        {items.map(item => (
          <HeaderItem
            item={item}
            className={activeDefaultTab.current === item.id ? 'selected' : ''}
          />
        ))}
      </ul>
    </Wrapper>
  );
}
