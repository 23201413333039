import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './scripts/reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Error from 'components/error';
import 'style/reset.css';
import 'style/style.css';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/:id" element={<App />}></Route>
        <Route
          path="*"
          element={<Error msg="Désolé cette page n'existe pas" />}
        ></Route>
      </Routes>
    </BrowserRouter>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
