import DrawerComponent from 'rc-drawer';
import { DrawerPopupProps } from 'rc-drawer/lib/DrawerPopup';
import 'rc-drawer/assets/index.css';
import './motion.scss';
type Props = Omit<DrawerPopupProps, 'prefixCls'>;
const motionOptions = {
  maskMotion: {
    motionAppear: true,
    motionName: 'mask-motion',
    onAppearEnd: console.warn,
  },
  motion: placement => ({
    motionAppear: true,
    motionName: `panel-motion-${placement}`,
  }),
};

export default function Drawer({ children, ...props }: Props) {
  return (
    <DrawerComponent
      {...props}
      destroyOnClose
      prefixCls="rc-drawer"
      {...motionOptions}
    >
      {children}
    </DrawerComponent>
  );
}
